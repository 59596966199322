import { useState } from 'react';
import {
  ChecklistCollection,
  EssentialChecklistCollection,
  KeywordsCollection,
  LocationsCollection,
  StatsCollection,
} from '../collections';
import { StatsType } from '../types/types';
import { getDateString, getFormattedDateString } from 'src/utils/app/string';
import { Timestamp } from 'firebase/firestore';
import { addDocument, deleteDocument, updateDocument } from '../firebase-utils';

interface BaseProps {
  locationId: string;
}

interface AddKeywordProps extends BaseProps {
  keyword: string;
  statsType?: StatsType;
  documentId?: string;
}

interface AddToDoProps extends BaseProps {
  title: string;
  collectionKey: string;
}

interface UpdateToDoProps extends BaseProps {
  isChecked: boolean;
  todoCollectionId: string;
  todoDocumentId: string;
  title?: string;
}

interface DeleteProps extends BaseProps {
  key: string;
}

interface AddDataResponse {
  success: boolean;
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  addToDo: (data: AddToDoProps) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  updateToDo: (data: UpdateToDoProps) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  deleteToDo: (data: DeleteProps) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  addKeyword: (data: AddKeywordProps) => Promise<string>;
  // eslint-disable-next-line no-unused-vars
  deleteKeyword: (data: DeleteProps) => Promise<void>;
}

const useAddData = (): AddDataResponse => {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const addKeyword = async ({
    keyword,
    locationId,
    statsType,
    documentId,
  }: AddKeywordProps) => {
    setSuccess(false);
    setIsLoading(true);
    const data = {
      title: keyword,
      strength: 0,
      competitorsCount: 0,
      movement: 0,
    };
    let collectionId = '';
    if (statsType) {
      const formattedDate = getFormattedDateString(
        getDateString(new Date()),
        statsType,
      );
      collectionId = `${StatsCollection}/${locationId}/${statsType}/${formattedDate}/${KeywordsCollection}`;
    } else {
      collectionId = `${LocationsCollection}/${locationId}/${KeywordsCollection}`;
    }

    const result = await addDocument(data, collectionId, documentId);
    const keywordDocumentId = result.documentId;

    setSuccess(true);
    setIsLoading(false);
    return keywordDocumentId;
  };

  const deleteKeyword = async ({ key, locationId }: DeleteProps) => {
    if (!key || !locationId) return;

    const date = getDateString();
    const monthlyPath = `${StatsCollection}/${locationId}/${StatsType.monthly}/${getFormattedDateString(date, StatsType.monthly)}/${KeywordsCollection}`;
    const yearlyPath = `${StatsCollection}/${locationId}/${StatsType.yearly}/${getFormattedDateString(date, StatsType.yearly)}/${KeywordsCollection}`;
    const dailyPath = `${StatsCollection}/${locationId}/${StatsType.daily}/${getFormattedDateString(date, StatsType.daily)}/${KeywordsCollection}`;

    const deleteDocuments = async (path: string) => {
      await deleteDocument(key, path);
    };

    await Promise.all([
      deleteDocuments(monthlyPath),
      deleteDocuments(yearlyPath),
      deleteDocuments(dailyPath),
    ]);
  };

  const addToDo = async ({
    title,
    locationId,
    collectionKey,
  }: AddToDoProps) => {
    setSuccess(false);
    setIsLoading(true);
    const data = {
      title,
      checked: false,
      updatedAt: Timestamp.fromDate(new Date()),
    };

    const collectionId = `${ChecklistCollection}/${locationId}/${collectionKey}`;
    addDocument(data, collectionId);
    setSuccess(true);
    setIsLoading(false);
  };

  const updateToDo = async ({
    locationId,
    isChecked,
    todoCollectionId,
    todoDocumentId,
    title,
  }: UpdateToDoProps) => {
    setSuccess(false);
    setIsLoading(true);
    const data = {
      checked: isChecked,
      updatedAt: Timestamp.fromDate(new Date()),
    };

    const documentId = `${ChecklistCollection}/${locationId}/${todoCollectionId}/${todoDocumentId}`;
    if (title) {
      updateDocument({ ...data, title }, documentId);
    } else {
      updateDocument(data, documentId);
    }
    setSuccess(true);
    setIsLoading(false);
  };

  const deleteToDo = async ({ key, locationId }: DeleteProps) => {
    if (!key || !locationId) return;

    const collectionId = `${ChecklistCollection}/${locationId}/${EssentialChecklistCollection}`;
    await deleteDocument(key, collectionId);
  };

  return {
    success,
    isLoading,
    addKeyword,
    addToDo,
    updateToDo,
    deleteToDo,
    deleteKeyword,
  };
};

export { useAddData };
