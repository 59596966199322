import React, { useState } from 'react';
import { Screen } from 'src/components/screen';
import { DashboardHeader } from '../components/header/header';
import {
  Box,
  CircularProgress,
  Grid,
  ListItem,
  Typography,
} from '@mui/material';
import {
  container,
  infoContainer,
  listItem,
  gridContainer,
  noReviewsContainer,
  noReviewsTitle,
  noReviewsSubtitle,
  progressContainer,
} from './styles';
import { useWindowDimensions } from 'src/utils/ui/dimensions';
import { useReviews } from 'src/api/hooks/use-reviews';
import { useAppAuth } from 'src/context/use-app-auth';
import { ReviewCard } from './review-item';
import { reviewLabels } from 'src/text/locales/default-en';
import { ReviewDialog } from './review-dialog';
import { Review } from 'src/api/types/types';
import { conditionalRender } from 'src/utils/ui/conditional-render';
import { ReactComponent as IconNoReviews } from 'src/assets/svg/icon-no-reviews.svg';

const ReviewsScreen: React.FC = () => {
  const { isMobile, height } = useWindowDimensions();
  const { location } = useAppAuth();
  const { reviews, loading } = useReviews({ locationId: location?.key });

  const [selectedReview, setSelectedReview] = useState<Review | undefined>();

  const handleOnReviewPress = (review: Review) => {
    setSelectedReview(review);
  };

  return (
    <Screen>
      <Box sx={{ ...container, minHeight: height }}>
        <DashboardHeader selection={2} />
        <Box mt={8} sx={infoContainer}>
          <Typography variant="h3">{reviewLabels.title}</Typography>

          {conditionalRender(
            loading,
            <Box sx={progressContainer}>
              <CircularProgress size={40} />
            </Box>,
          )}

          {!loading && reviews.length > 0 ? (
            <Grid sx={gridContainer} container spacing={2}>
              {reviews.map(review => (
                <Grid item xs={12} md={isMobile ? 12 : 6} key={review.reviewId}>
                  <ListItem sx={listItem}>
                    <ReviewCard review={review} onPress={handleOnReviewPress} />
                  </ListItem>
                </Grid>
              ))}
            </Grid>
          ) : (
            !loading && <NoReviewsPlaceholder />
          )}
        </Box>
      </Box>

      {selectedReview && (
        <ReviewDialog
          review={selectedReview}
          onClose={() => setSelectedReview(undefined)}
        />
      )}
    </Screen>
  );
};

const NoReviewsPlaceholder = () => {
  return (
    <Box sx={noReviewsContainer}>
      <IconNoReviews width={25} height={40} />
      <Typography variant="h6" mt={3} sx={noReviewsTitle}>
        {reviewLabels.noReviewsTitle}
      </Typography>
      <Typography mt={2} sx={noReviewsSubtitle}>
        {reviewLabels.noReviewsSubtitle}
      </Typography>
    </Box>
  );
};

export { ReviewsScreen };
