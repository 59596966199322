import axios, { AxiosError } from 'axios';

function logAxiosError(error: unknown) {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      console.error('Error response status:', axiosError.response.status);
      console.error('Error response data:', axiosError.response.data);
      console.error('Error response headers:', axiosError.response.headers);
    } else if (axiosError.request) {
      console.error('Error request:', JSON.stringify(axiosError.request));
    } else {
      console.error('Error message:', axiosError.message);
    }
  } else {
    console.error('Error:', error);
  }
}

export { logAxiosError };
