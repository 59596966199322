import { SxProps } from '@mui/material';
import { GLOBAL_MARGIN_HORIZONTAL } from 'src/app/constants';

const container: SxProps = {
  flex: 1,
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  height: '100vh',
  paddingBottom: GLOBAL_MARGIN_HORIZONTAL * 2,
};

const listItem: SxProps = {
  paddingLeft: 0,
  paddingRight: GLOBAL_MARGIN_HORIZONTAL,
};

export { container, listItem };
