import React from 'react';
import { List, ListItem, SxProps, Theme } from '@mui/material';
import { StatsCard } from 'src/components/stats-card';
import { Stats } from 'src/api/types/types';

interface KeywordListProps {
  isEmptyData: boolean;
  keywordsStatsData: Stats[];
  keywordLabels: { delete: string };
  listItem: SxProps<Theme>;
  handleDeleteKeyword: (index: number) => void;
}

const KeywordList: React.FC<KeywordListProps> = ({
  isEmptyData,
  keywordsStatsData,
  keywordLabels,
  listItem,
  handleDeleteKeyword,
}) => {
  const menuOptions = isEmptyData ? [] : [keywordLabels.delete];
  const handleOptionsSelected = (index: number, statId: string | undefined) => {
    if (index === 0) {
      const statIndex = keywordsStatsData.findIndex(
        stats => stats.key === statId,
      );
      if (statIndex !== -1) {
        handleDeleteKeyword(statIndex);
      }
    }
  };
  return (
    <List>
      {keywordsStatsData.map(stats => (
        <ListItem key={stats.key ?? stats.title} sx={listItem}>
          <StatsCard
            statsId={stats.key}
            movement={stats.overallMovement}
            subtitle={isEmptyData ? stats.subtitle : ''}
            strength={stats.strength}
            competitorsCount={stats.competitorsCount}
            title={stats.title}
            menuOptions={menuOptions}
            onOptionSelected={handleOptionsSelected}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default KeywordList;
