import React from 'react';
import { Grid, ListItem, SxProps, Theme } from '@mui/material';
import { Stats } from 'src/api/types/types';
import { StatsCard } from 'src/components/stats-card';

interface KeywordGridProps {
  isEmptyData: boolean;
  keywordsStatsData: Stats[];
  keywordLabels: { delete: string };
  handleDeleteKeyword: (index: number) => void;
  listItem: SxProps<Theme>;
}

const KeywordGrid: React.FC<KeywordGridProps> = ({
  isEmptyData,
  keywordsStatsData,
  keywordLabels,
  handleDeleteKeyword,
  listItem,
}) => {
  const menuOptions = isEmptyData ? [] : [keywordLabels.delete];
  const handleOptionsSelected = (index: number, statId: string | undefined) => {
    if (index === 0) {
      const statIndex = keywordsStatsData.findIndex(
        stats => stats.key === statId,
      );
      if (statIndex !== -1) {
        handleDeleteKeyword(statIndex);
      }
    }
  };

  return (
    <Grid container spacing={0}>
      {keywordsStatsData.map(stats => (
        <Grid item key={stats.key ?? stats.title}>
          <ListItem sx={listItem}>
            <StatsCard
              statsId={stats.key}
              movement={stats.overallMovement}
              subtitle={isEmptyData ? stats.subtitle : ''}
              strength={stats.strength}
              competitorsCount={stats.competitorsCount}
              title={stats.title}
              menuOptions={menuOptions}
              onOptionSelected={handleOptionsSelected}
            />
          </ListItem>
        </Grid>
      ))}
    </Grid>
  );
};

export default KeywordGrid;
