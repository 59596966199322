import React from 'react';
import { Grid, ListItem } from '@mui/material';
import { Stats } from 'src/api/types/types';
import { StatsCard } from 'src/components/stats-card';
import { listItem } from '../styles';

interface ReviewGridProps {
  data: Stats[];
}

const ReviewStatsGrid: React.FC<ReviewGridProps> = ({ data }) => {
  return (
    <Grid container spacing={0}>
      {data.map(stats => (
        <Grid item key={stats.key ?? stats.title}>
          <ListItem sx={listItem}>
            <StatsCard
              isReviewStats
              subtitle={stats.subtitle}
              statsId={stats.key ?? stats.title}
              movement={stats.overallMovement}
              strength={Math.round(stats.strength)}
              competitorsCount={stats.competitorsCount}
              title={stats.title}
            />
          </ListItem>
        </Grid>
      ))}
    </Grid>
  );
};

export default ReviewStatsGrid;
