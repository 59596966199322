import React, { ReactNode, useState } from 'react';
import { Box, SxProps } from '@mui/material';
import {
  container,
  contentContainer,
  menuContainer,
  mobileContainer,
  mobileContentContainer,
} from './styles';
import { HeaderBar } from './header';
import { mergeStyles } from 'src/utils/ui/merge-styles';
import { useWindowDimensions } from 'src/utils/ui/dimensions';
import { MobileFooterBar } from './mobile-footer';
import { MobileHeaderBar } from './mobile-header';
import { MobileButtons } from './mobile-buttons';

type Props = {
  children: ReactNode | ReactNode[];
  contentContainerStyle?: SxProps;
};

const Screen: React.FC<Props> = ({ children, contentContainerStyle }) => {
  const { isMobile } = useWindowDimensions();
  const [openMenu, setOpenMenu] = useState(false);

  const contentStyle = mergeStyles(
    isMobile ? mobileContainer : container,
    contentContainerStyle,
  );

  const childContainerStyle = isMobile
    ? mobileContentContainer
    : contentContainer;

  return (
    <>
      {!openMenu && (
        <Box sx={contentStyle}>
          <HeaderBar onOpenMenu={() => setOpenMenu(true)} />
          <Box sx={childContainerStyle}>{children}</Box>
        </Box>
      )}

      {openMenu && (
        <Box sx={menuContainer} className={openMenu ? 'menuOpen' : 'menuClose'}>
          <Box>
            <MobileHeaderBar closeMenu={() => setOpenMenu(false)} />
            <MobileButtons />
          </Box>
          <MobileFooterBar />
        </Box>
      )}
    </>
  );
};

Screen.defaultProps = {
  contentContainerStyle: undefined,
};

export { Screen };
