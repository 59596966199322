import { StatsType } from 'src/api/types/types';
import { formatDistanceToNow, Locale } from 'date-fns';
import { enUS, es, it } from 'date-fns/locale';

function getDateString(date: Date = new Date()) {
  return date.toLocaleDateString('en-US');
}

function getFormattedDateString(dateStr: string, type: StatsType) {
  const date = new Date(dateStr);
  const day = date.getDay().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month index
  const year = date.getFullYear().toString();
  if (type === StatsType.daily) {
    return `${day}-${month}-${year}`;
  } else if (type === StatsType.monthly) {
    return `${month}-${year}`;
  } else {
    return `${year}`;
  }
}

function getRelativeTime(createTime: string, localeString: string): string {
  const date = new Date(createTime);
  let locale: Locale;

  switch (localeString) {
    case 'it':
      locale = it;
      break;
    case 'es':
      locale = es;
      break;
    default:
      locale = enUS;
  }

  return formatDistanceToNow(date, { addSuffix: true, locale });
}

export { getDateString, getFormattedDateString, getRelativeTime };
