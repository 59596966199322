import { SxProps } from '@mui/material';
import { GLOBAL_MARGIN_HORIZONTAL } from 'src/app/constants';
import { brand } from 'src/themes/palette';

const container: SxProps = {
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: GLOBAL_MARGIN_HORIZONTAL,
};

const infoContainer: SxProps = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
};

const gridContainer: SxProps = {
  marginTop: 2,
  marginLeft: -2,
};

const listItem: SxProps = {};

const progressContainer: SxProps = {
  height: '60%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const progressPrimary: SxProps = {
  color: brand.primary,
};

const noReviewsContainer: SxProps = {
  marginTop: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
};

const noReviewsTitle: SxProps = {};
const noReviewsSubtitle: SxProps = {
  textAlign: 'center',
  fontFamily: 'Roboto',
  fontSize: '14px',
};

export {
  container,
  listItem,
  infoContainer,
  gridContainer,
  progressContainer,
  progressPrimary,
  noReviewsContainer,
  noReviewsTitle,
  noReviewsSubtitle,
};
